// IMPORTING LIBRARIES
import React from 'react'

// IMPORTING STYLE
import './blackBtn.css'

export default function BlackBtn({ children, style, type }) {
    return (
        <button className='black-button' type={type} style={style}>{children}</button>
    )
}