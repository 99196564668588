// IMPORTING LIBRARIES
import {
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { Route, RouterProvider } from "react-router-dom";

// IMPORTING COMPONENTS
import Router from 'Common/Temp/Router/Router';
import Home from 'Pages/Home/Home';
import AboutUs from 'Pages/AboutUs/AboutUs';
import ContactUs from 'Pages/ContactUs/ContactUs';
import Services from 'Pages/Services/Services';

// IMPORTING STYLE
import './mainStyle.css'

//  ROUTING
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Router />}>
      <Route index element={<Home />} />
      <Route path="AboutUs" element={<AboutUs />} />
      <Route path="ContactUs" element={<ContactUs />} />
      <Route path="Services" element={<Services />} />
    </Route>
  )
);

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;