// IMPORTING LIBRARIES
import React from 'react'
import { useTranslation } from "react-i18next";

// IMPORTING COMPONENTS
import TitleContainer4 from 'Common/Components/TitleContainers/TitleContainer4/TitleContainer4'
import ServicesSectionCards from 'Pages/Services/Components/ServicesSectionCards/ServicesSectionCards';

//  IMPORTING SECTION'S DATA
import { cards } from './servicesData';

// IMPORTING STYLE
import './servicesSection.css'
import './servicesSectionResponsive.css'

// TRANSLATION TAG FOR COMPONENT'S TEXTUAL CONTENT
const translationTag = "pages.services.services_section."

export default function ServicesSection() {
    const { t } = useTranslation();
    return (
        <div className='services__services-section'>
            <div className='rows'>
                <div className="row">
                    <div className='card title__container'>
                        <TitleContainer4
                            upperTitle={t(translationTag + "title.upper_title")}
                            mainTitle={t(translationTag + "title.lower_title")}
                            rectangleIcon={true}
                        />
                    </div>
                    {cards.filter((card, index) => index < 2).map((card) => (
                        <div key={card.index} className="card">
                            <ServicesSectionCards
                                index={card.index}
                                image={card.icon}
                                title={t(card.title)}
                                content={t(card.content)}
                            />
                        </div>
                    ))}
                </div>
                <div className="row">
                    {cards.filter((card, index) => index >= 2 && index < 5).map((card) => (
                        <div key={card.index} className="card">
                            <ServicesSectionCards
                                index={card.index}
                                image={card.icon}
                                title={t(card.title)}
                                content={t(card.content)}
                            />
                        </div>
                    ))}
                </div>
                <div className="row">
                    {cards.filter((card, index) => index >= 5).map((card) => (
                        <div key={card.index} className="card">
                            <ServicesSectionCards
                                index={card.index}
                                image={card.icon}
                                title={t(card.title)}
                                content={t(card.content)}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}