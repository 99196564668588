// IMPORTING LIBRARIES
import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next";

// IMPORTING COMPONENTS
import AboutUsSection from './Sections/AboutUsSection/AboutUsSection';
import ServicesSection from './Sections/ServicesSection/ServicesSection';
import SectorsSection from './Sections/SectorsSection/SectorsSection';
import SectorsSectionMobile from './Sections/SectorsSection/SectorsSectionMobile';
import ClientSection from './Sections/ClientSection/ClientSection';

// IMPORTING STYLE
import './home.css'
import './homeResponsive.css'
import EnquiryFormMobile from 'Common/Components/EnquiryForm/EnquiryFormMobile';

// TRANSLATION TAG FOR COMPONENT'S TEXTUAL CONTENT
const translationTag = "pages.home."

export default function Home() {
    useEffect(() => {
        document.title = "Home | MEPG";
        window.scrollTo(0, 0);
    }, []);
    const { t } = useTranslation();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div>
            <div className='home__background'>
                <div className='container'>
                    <h1>{t(translationTag + "background_text")}</h1>
                </div>
                {
                    windowWidth > 768 ? null :
                        <div className='drop-down-enquiry-form'>
                            <EnquiryFormMobile></EnquiryFormMobile>
                        </div>
                }
            </div>
            <AboutUsSection form={windowWidth > 768 ? true : false} />
            <ServicesSection />
            {
                windowWidth > 768 ?
                    <SectorsSection /> : <SectorsSectionMobile />
            }
            <ClientSection />
        </div>
    )
}