// IMPORTING LIBRARIES
import React from 'react'

// IMPORTING STYLE
import "./valuesSectionCards.css"

const imageStyle = {
    width: '80%',
    height: 'auto',
}

export default function ValuesSectionCards({ index, image, title, content }) {
    return (
        <div id={index} className='aboutus__values-section__card'>
            <div className='icon__container'>
                <img src={image} alt={index} style={imageStyle} />
            </div>
            <h3>{title}</h3>
            <h4>{content}</h4>
        </div>
    )
}