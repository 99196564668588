// IMPORTING LIBRARIES
import React, { useEffect } from 'react'
import { useTranslation } from "react-i18next";

// IMPORTING COMPONENTS
import ServicesSection from './Sections/ServicesSection/ServicesSection';
import SectorsSection from './Sections/SectorsSection/SectorsSection';
import YellowBtn from 'Common/Components/Buttons/YellowBtn/YellowBtn'

// IMPORTING STYLE
import './services.css'

// TRANSLATION TAG FOR COMPONENT'S TEXTUAL CONTENT
const translationTag = "pages.services."

export default function Services() {
    useEffect(() => {
        document.title = "Services | MEPG";
        window.scrollTo(0, 0);
    }, []);
    const { t } = useTranslation();
    return (
        <div>
            <div className='services__background'>
                <div className='container'>
                    <h1>{t(translationTag + "background_text")}</h1>
                    <h2>{t(translationTag + "background_sub_text")}
                        <div className='button__container'>
                            <YellowBtn
                                href={'ContactUs'}
                            >
                                {t(translationTag + "header_button")}
                            </YellowBtn>
                        </div>
                    </h2>
                </div>
            </div>
            <ServicesSection />
            <SectorsSection />
        </div>
    )
}