// IMPORTING LIBRARIES
import React from 'react'
import { useTranslation } from "react-i18next";

// IMPORTING COMPONENTS
import ContactCardsSectionCard from 'Pages/ContactUs/Components/ContactCardsSectionCard/ContactCardsSectionCard';

//  IMPORTING SECTION'S DATA
import { cards } from './contactCardsData';

// IMPORTING STYLE
import './contactCardsSection.css'

export default function ContactCardsSection() {
    const { t } = useTranslation();
    return (
        <div className='contactus__contact-cards-section'>
            <div className='container'>
                <div className="list">
                    {cards.map((card) => (
                        <ContactCardsSectionCard
                            key={card.index}
                            index={card.index}
                            image={card.image}
                            title={t(card.title)}
                            content={t(card.content)}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}