// IMPORTING LIBRARIES
import React from 'react'

// IMPORTING STYLE
import "./sectorsSectionCard.css"

export default function SectorsSectionCard({ index, image, tag }) {
    return (
        <div id={index} className='home__sectors-section__card'>
            <img src={image} alt={index} />
            <h3>{tag}</h3>
        </div>
    )
}