// IMPORTING LIBRARIES
import React, { useState, useEffect } from "react";
import Cookies from 'js-cookie';
import { useTranslation } from "react-i18next";

// IMPORTING COMPONENTS
import RightDashNavigationBtn from "../../../Buttons/RightDashNavigationBtn/RightDashNavigationBtn";

// IMPORTING FUNCTIONS
import ConvertNum from "Common/Utils/ConvertNum";

// IMPORTING STYLE
import "./slide.css"
import "./slideResponsive.css"

// TRANSLATION TAG FOR COMPONENT'S TEXTUAL CONTENT
const translationTag = "pages.home.services_section."

export default function Slide({ index, image, title, text }) {
    const { t } = useTranslation();
    const [direction, setDirection] = useState({ right: ' calc(50vw + 30px)' });
    const currentLanguageCode = Cookies.get('i18next') || 'en';
    useEffect(() => {
        if (currentLanguageCode === "ar") {
            setDirection({ left: ' calc(50vw + 30px)' });
        } else {
            setDirection({ right: ' calc(50vw + 30px)' });
        }
    }, [t])

    return (
        <div id={index} className='swipeable-slide'>
            <img src={image} alt={index} />
            <span className='index' style={direction}>.{ConvertNum(index + 1, currentLanguageCode)}</span>
            <div className='content'>
                <span >{title}</span>
                <p>{text}</p>
                <div className='button__container'>
                    <RightDashNavigationBtn title={t(translationTag + "button")} href="/Services" style={{ padding: '4px 23px' }} />
                </div>
            </div>
        </div>
    )
}