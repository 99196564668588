// IMPORTING LIBRARIES
import React, { useEffect, useRef } from 'react'
import { useTranslation } from "react-i18next";

// IMPORTING STYLE
import './writeToUsForm.css'
import BlackBtn from '../Buttons/BlackBtn/BlackBtn';

// TRANSLATION TAG FOR COMPONENT'S TEXTUAL CONTENT
const translationTag = "components.write_to_us_form."

export default function WriteToUsForm() {
    const { t } = useTranslation();
    const [formStatus, setFormStatus] = React.useState("")
    const scrollRef = useRef(null);
    const onSubmit = (e) => {
        e.preventDefault()
        setFormStatus('Submit')
        const { name, email, phoneNumber } = e.target.elements
        let conFom = {
            name: name.value,
            email: email.value,
            phoneNumber: phoneNumber.value,
        }
        /* Log inputs to console*/
        console.log(conFom)
    }

    useEffect(() => {
        setFormStatus(t(translationTag + "button"))
    }, [t])

    /*  Function to scroll down when "REQUEST A QOUTA" button is pressed.   */
    function scrollDown() {
        scrollRef.current?.scrollIntoView({ block: "start", behavior: 'smooth' });
    }

    return (
        <div className='write-to-us-form__container'>
            <div className='title__container'>
                <div className="curved-title__container">
                    <div className='position'>
                        <h2 className='title' onClick={scrollDown} onMouseEnter={scrollDown} >{t(translationTag + "header")}</h2>
                    </div>
                </div>
            </div>
            <form onSubmit={onSubmit} className='write-to-us-form' ref={scrollRef}>
                <div className='input__container'>
                    <div className='wrap' >
                        <label htmlFor="name" className="name__label" >{t(translationTag + "name")}</label>
                        <input type="text" className="name__input" id="name" required />
                    </div>
                    <div className='wrap'>
                        <label htmlFor="email" className='email__label'>{t(translationTag + "email")}</label>
                        <input type="text" className="email__input" id="email" required />
                    </div>
                    <div className='wrap'>
                        <label htmlFor="phoneNumber" className='phone__label' >{t(translationTag + "phone")}</label>
                        <input type="text" className="phone__input" id="phoneNumber" required />
                    </div>
                    <div className='wrap'>
                        <label htmlFor="subject" className='subject__label' >{t(translationTag + "subject")}</label>
                        <input type="text" className="subject__input" id="subject" required />
                    </div>
                    <div className='wrap'>
                        <label htmlFor="message" className='message__label' >{t(translationTag + "message")}</label>
                        <input type="text" className="message__input" id="message" required />
                    </div>
                </div>
                <div className='button__containter'>
                    <BlackBtn
                        style={{ minWidth: "300px" }}
                        type={"submit"}
                    >
                        {formStatus}
                    </BlackBtn>
                </div>
            </form>
        </div>
    )
}