// IMPORTING IMAGES/ICONS
import {
    expo2016AntalyaIcon,
    gumusPastaIcon,
    joyPartIcon,
    kirkFirinIcon,
    lokmahaneIcon,
    vHotelsIcon,
} from "../../../Images/ImageComponents";

// IMPORTING STYLE
import './clients.css';
import './clientsResponsive.css';


export const clients = [
    {
        index: 0,
        image: gumusPastaIcon,
    },
    {
        index: 1,
        image: vHotelsIcon,
    },
    {
        index: 2,
        image: expo2016AntalyaIcon,
    },
    {
        index: 3,
        image: kirkFirinIcon,
    },
    {
        index: 4,
        image: lokmahaneIcon,
    },
    {
        index: 5,
        image: joyPartIcon,
    },
];