// IMPORTING LIBRARIES
import React from 'react'
import { useTranslation } from "react-i18next";

// IMPORTING COMPONENTS
import TitleContainer1 from 'Common/Components/TitleContainers/TitleContainer1/TitleContainer1';
import SectorsSectionCard from '../../Components/SectorsSectionCard/SectorsSectionCard'

//  IMPORTING SECTION'S DATA
import { sectors } from './sectorsData';

// IMPORTING STYLE
import './sectorsSection.css'
import './sectorsSectionResponsive.css'

// TRANSLATION TAG FOR COMPONENT'S TEXTUAL CONTENT
const translationTag = "pages.home.sectors_section."

export default function SectorsSection() {
    const { t } = useTranslation();
    return (
        <div className='home__sectors-section'>
            <div className='container'>
                <TitleContainer1
                    upperTitle={t(translationTag + "upper_title")}
                    mainTitle={t(translationTag + "main_title")}
                    rectangleIcon={true}
                />
                <div className="icons">
                    {sectors.map((sector) => (
                        <SectorsSectionCard
                            key={sector.index}
                            index={sector.index}
                            image={sector.image}
                            tag={t(sector.tag)}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}