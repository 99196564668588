// IMPORTING LIBRARIES
import React from 'react'
import { useTranslation } from "react-i18next";

//  IMPORTING SECTION'S DATA
import { icons } from './sectorsSectionData';

// IMPORTING IMAGES/ICONS
import {
    handIconServices
} from "../../../../Images/ImageComponents"

// IMPORTING STYLE
import './sectorsSection.css'
import './sectorsSectionResponsive.css'

// TRANSLATION TAG FOR COMPONENT'S TEXTUAL CONTENT
const translationTag = "pages.services.sectors_section."

export default function SectorsSection() {
    const { t } = useTranslation();
    return (
        <div className='services__sectors-section'>
            <h1>{t(translationTag + "sub_title")}</h1>
            <div className='icons__container'>
                <div className='icons'>
                    {icons.map((icon) => (
                        <div className='icon__card' key={icon.index}>
                            <img src={icon.icon}
                                alt={icon.title}></img>
                            <h2>{t(icon.title)}</h2>
                        </div>
                    ))}
                </div>
            </div>
            <img
                className='hand__icon' src={handIconServices}
                alt='hand__icon'
            />
        </div >
    )
}