// IMPORTING LIBRARIES
import React from 'react'

// IMPORTING STYLE
import './loading.css'

export default function Loading() {
    return (
        <div className='loading__container'>
            <div className="loader">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <h1>
                Loadin
                <span className='loading__inline'>
                    g
                </span>
                ...
            </h1>
        </div>
    )
}