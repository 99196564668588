// IMPORTING LIBRARIES
import React, { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next";
import Cookies from 'js-cookie';

// IMPORTING COMPONENTS
import Slide from './Slide/Slide';

// IMPORTING STYLE
import "./arrowSlider.css"

export default function ArrowSlider({ slides }) {
    const { t } = useTranslation();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const currentLanguageCode = Cookies.get('i18next') || 'en';
    const [totalSlides, setTotalSlides] = useState(slides.length); // Total number of slides
    const [currentSlide, setCurrentSlide] = useState(0);
    const [directionIndicator, setDirectionIndicator] = useState(1);
    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (currentLanguageCode === "ar") {
            setDirectionIndicator(1);
        } else {
            setDirectionIndicator(-1);
        }
    }, [t]);

    const goToSlide = (index) => {
        const slider = document.querySelector('.arrow-slider');
        let slideWidth;
        if (currentLanguageCode === "ar") {
            slideWidth = -windowWidth;
        } else {
            slideWidth = windowWidth;
        }
        if (slider && index >= 0 && index < totalSlides) {
            slider.scrollLeft = slideWidth * index;
            setCurrentSlide(index);
        }
    };
    return (
        <div className="arrow-slider">
            {slides.map((slide) => (
                <Slide
                    key={slide.index}
                    index={slide.index}
                    image={slide.image}
                    tag={slide.tag}
                    prevSlide={() => goToSlide(slide.index + (directionIndicator))}
                    nextSlide={() => goToSlide(slide.index - (directionIndicator))}
                />
            ))}
        </div>
    )
}