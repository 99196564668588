// IMPORTING LIBRARIES
import React from 'react'
import { useTranslation } from "react-i18next";
import Cookies from 'js-cookie';

// IMPORTING IMAGES/ICONS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';

// IMPORTING STYLE
import "./dropDownMenu.css"
import "./dropDownMenuResponsive.css"

// TRANSLATION TAG FOR COMPONENT'S TEXTUAL CONTENT
const translatioTag = "nav."

const selectedLanguageStyle = {
    backgroundColor: '#F0A758',
    color: 'white'
}
export default function DropDownMenu({ languages, customOnClick }) {
    const { t, i18n } = useTranslation();
    const currentLanguageCode = Cookies.get('i18next') || 'en';

    // Function to handle click event
    const handleClick = (lang) => {
        i18n.changeLanguage(lang);
        if (customOnClick) {
            customOnClick();
        }
    };

    return (
        <div className='lang-dropdown-menu'>
            <div className="icon" >
                <FontAwesomeIcon icon={faGlobe} />
            </div>
            <div className="content">
                {/* 
                Changing the style of the current 
                language in the drop down menu 
                */}
                {languages.map(lang => (currentLanguageCode === lang ?
                    <div className='item ' onClick={() => handleClick(lang)} style={selectedLanguageStyle} key={lang}>
                        {t(translatioTag + lang)}
                    </div>
                    :
                    <div className='item ' onClick={() => handleClick(lang)} key={lang}>
                        {t(translatioTag + lang)}
                    </div>
                ))}
            </div>
        </div>
    )
}