// IMPORTING LIBRARIES
import React, { useEffect, useRef } from 'react'
import { useTranslation } from "react-i18next";

// IMPORTING CSS
import './enquiryForm.css'
import BlackBtn from '../Buttons/BlackBtn/BlackBtn';

// TRANSLATION TAG FOR COMPONENT'S TEXTUAL CONTENT
const translationTag = "components.enquiry_form."

export default function EnquiryForm() {
    const { t } = useTranslation();
    const [formStatus, setFormStatus] = React.useState("");
    const scrollRef = useRef(null);
    const onSubmit = (e) => {
        e.preventDefault()
        setFormStatus('Submit')
        const { name, email, phoneNumber } = e.target.elements
        let conFom = {
            name: name.value,
            email: email.value,
            phoneNumber: phoneNumber.value,
        }
        /* Log inputs to console*/
        console.log(conFom)
    }

    /*  Function to scroll down when "REQUEST A QOUTA" button is pressed.   */
    function scrollDown() {
        scrollRef.current?.scrollIntoView({ block: "start", behavior: 'smooth' });
    }

    useEffect(() => {
        setFormStatus(t(translationTag + "button"))
    }, [t])

    return (
        <form onSubmit={onSubmit} className='enquiry-form' ref={scrollRef}>
            <h2 className='header' onClick={scrollDown} onMouseEnter={scrollDown} >{t(translationTag + "header")}</h2>
            <div className='wrap' >
                <label htmlFor="name" className="name__label" >{t(translationTag + "name")}</label>
                <input type="text" className="name__input" id="name" autoComplete="on" required />
            </div>

            <div className='wrap'>
                <label htmlFor="email" className='email__label'>{t(translationTag + "email")}</label>
                <input type="text" className="email__input" id="email" autoComplete="on" required />
            </div>

            <div className='wrap'>
                <label htmlFor="phoneNumber" className='phone__label' >{t(translationTag + "phone")}</label>
                <input type="text" className="phone__input" id="phoneNumber" autoComplete="on" required />
            </div>

            <div className='button__containter'>
                <BlackBtn>{formStatus}</BlackBtn>
            </div>
        </form>
    )
}