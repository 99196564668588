// IMPORTING LIBRARIES
import React from 'react'

// IMPORTING IMAGES/ICONS
import { titleIcon2 } from 'Images/ImageComponents'

// IMPORTING STYLE
import "./titleContainer2.css"

export default function TitleContainer2({ children }) {
    return (
        <div className='title-container-2'>
            <img src={titleIcon2} alt="logo_container" className='up-line'></img>
            <h1>{children}</h1>
            <img src={titleIcon2} alt="logo_container" className='down-line'></img>
        </div>
    )
}