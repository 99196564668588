// IMPORTING IMAGES/ICONS
import {
    addressIcon,
    phoneIcon,
    emailIcon,
    workingHoursIcon
} from "../../../../Images/ImageComponents";

// TRANSLATION TAG FOR COMPONENT'S TEXTUAL CONTENT
const translationTag = "pages.contact_us.contact_cards_section."

export const cards = [
    {
        index: 0,
        image: addressIcon,
        title: translationTag + "address_card.title",
        content: translationTag + "address_card.content",
    },
    {
        index: 1,
        image: phoneIcon,
        title: translationTag + "phone_card.title",
        content: translationTag + "phone_card.content",
    },
    {
        index: 2,
        image: emailIcon,
        title: translationTag + "email_card.title",
        content: translationTag + "email_card.content",
    },
    {
        index: 3,
        image: workingHoursIcon,
        title: translationTag + "working_hours_card.title",
        content: translationTag + "working_hours_card.content",
    }
];