// IMPORTING LIBRARIES
import React from 'react'

// IMPORTING STYLE
import './yellowBtn.css'

export default function YellowBtn({ href, style, children }) {
    return (
        <a
            className='yellow-button'
            style={style}
            href={href} >
            {children}
        </a>
    )
}