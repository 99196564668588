// IMPORTING LIBRARIES
import React from 'react'
import { useTranslation } from "react-i18next";

// IMPORTING COMPONENTS
import TitleContainer1 from 'Common/Components/TitleContainers/TitleContainer1/TitleContainer1';
import EnquiryForm from 'Common/Components/EnquiryForm/EnquiryForm';
import RightDashNavigationBtn from 'Common/Components/Buttons/RightDashNavigationBtn/RightDashNavigationBtn';

// IMPORTING STYLE
import './aboutUsSection.css'
import './aboutUsSectionResponsive.css'

// TRANSLATION TAG FOR COMPONENT'S TEXTUAL CONTENT
const translationTag = "pages.home.aboutus_section."

export default function AboutUsSection({ form }) {
    const { t } = useTranslation();
    if (form) {
        return (
            <div className='home__aboutus-section'>
                <div className='container'>
                    <div className='left-bar'>
                        <div className='title'>
                            <TitleContainer1
                                upperTitle={t(translationTag + "upper_title")}
                                mainTitle={t(translationTag + "main_title")}
                                rectangleIcon={false}
                            />
                        </div>
                        <div className='paragraph'>
                            {t(translationTag + "paragraph")}
                        </div>
                        <div className='button'>
                            <RightDashNavigationBtn title={t(translationTag + "button")} href="/AboutUs" />
                        </div>
                    </div>
                    <div className='right-bar'>
                        <EnquiryForm></EnquiryForm>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className='home__aboutus-section'>
                <div className='container'>
                    <div className='left-bar'>
                        <div className='title'>
                            <TitleContainer1
                                upperTitle={t(translationTag + "upper_title")}
                                mainTitle={t(translationTag + "main_title")}
                                rectangleIcon={false}
                            />
                        </div>
                        <div className='paragraph'>
                            {t(translationTag + "paragraph")}
                        </div>
                        <div className='button'>
                            <RightDashNavigationBtn title={t(translationTag + "button")} href="/AboutUs" />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}