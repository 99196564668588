// IMPORTING LIBRARIES
import React, { useEffect } from 'react'
import { useTranslation } from "react-i18next";

// IMPORTING COMPONENTS
import ContactCardsSection from './Sections/ContactCardsSection/ContactCardsSection';
import FormSection from './Sections/FormSection/FormSection';

// IMPORTING STYLE
import './contactUs.css'

// TRANSLATION TAG FOR COMPONENT'S TEXTUAL CONTENT
const translationTag = "pages.contact_us."

export default function ContactUs() {
    useEffect(() => {
        document.title = "Contact Us | MEPG";
        window.scrollTo(0, 0);
    }, []);
    const { t } = useTranslation();
    return (
        <>
            <div className='contactus__background'>
                <div className='container'>
                    <h1>{t(translationTag + "background_text")}</h1>
                    <h2>{t(translationTag + "background_sub_text")}</h2>
                </div>
            </div>
            <ContactCardsSection />
            <FormSection />
        </>
    )
}