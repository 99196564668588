// IMPORTING LIBRARIES
import React from 'react'

// IMPORTING COMPONENTS
import Clients from 'Common/Components/Clients/Clients'

// IMPORTING STYLE
import './clientSection.css'

export default function ClientSection() {
    return (
        <Clients />
    )
}