// IMPORTING LIBRARIES
import React from 'react'

// IMPORTING STYLE
import "./card.css"

const imageStyle = {
    width: '10%',
    height: 'auto',
    filter: "grayscale(100%)",
}

export default function Card({ index, image }) {
    return (
        <img src={image} alt={index} style={imageStyle} className='client-card' />
    )
}