// IMPORTING LIBRARIES
import React, { useState, useEffect } from "react";
import { NavLink, Outlet } from "react-router-dom";
import Cookies from 'js-cookie';
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// IMPORTING COMPONENTS
import Footer from "Common/Components/Footer/Footer";
import DropDownMenu from "../Components/DropDownMenu/DropDownMenu";

// IMPORTING IMAGES/ICONS
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { goldLogo } from "Images/ImageComponents";

// IMPORTING STYLE
import "./router.css";
import "./routerResponsive.css";

// TRANSLATION TAG FOR COMPONENT'S TEXTUAL CONTENT
const translationTag = "nav.";

export default function Router() {
    const [isOpen, setIsOpen] = useState(false);
    const toggleOpen = () => setIsOpen(!isOpen);
    const { t } = useTranslation();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [navbarStyleRTL, setNavbarStyleRTL] = useState(
        {
            marginLeft: 'auto',
            marginRight: '0px',
        }
    );
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const currentLanguageCode = Cookies.get('i18next') || 'en';
        document.body.dir = currentLanguageCode === 'ar' ? 'rtl' : 'ltr';
        if (currentLanguageCode === "ar") {
            setNavbarStyleRTL(
                {
                    marginRight: 'auto',
                    marginLeft: '0px',
                }
            );
        } else {
            setNavbarStyleRTL(
                {
                    marginRight: '0px',
                    marginLeft: 'auto',
                }
            );
        }
    }, [t])

    return (
        <div>
            <header className='navbars__container'>
                {
                    windowWidth > 1028 ?
                        <div className="desktop-navbar">
                            <div className="left">
                                <NavLink to="/" >
                                    <img src={goldLogo} alt='MEPG' className="logo" />
                                </NavLink>
                            </div>
                            <nav className="right" style={navbarStyleRTL}>
                                <NavLink className="link" to="/" >{t(translationTag + "home")}</NavLink>
                                <NavLink className="link" to="Services" >{t(translationTag + "services")}</NavLink>
                                <NavLink className="link" to="AboutUs" >{t(translationTag + "about_us")}</NavLink>
                                <NavLink className="link" to="ContactUs" >{t(translationTag + "contactus")}</NavLink>
                                <DropDownMenu languages={['ar', 'en']}></DropDownMenu>
                            </nav>
                        </div>
                        :
                        <>
                            <div className="mobile-navbar">
                                <NavLink to="/" >
                                    <img src={goldLogo} alt='MEPG' className="logo" />
                                </NavLink>
                                <div className={`menu-icon ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
                                    <div className="hamburger-line"></div>
                                    <div className="hamburger-line"></div>
                                    <div className="hamburger-line"></div>
                                </div>
                            </div>
                            <div className={`modal-menu__container ${isOpen ? 'open' : ''}`}>
                                <div className="modal-menu">
                                    <div className="close-btn__container">
                                        <div className="close-btn" onClick={toggleMenu}>
                                            <div className="line"></div>
                                            <div className="line"></div>
                                        </div>
                                    </div>
                                    <div className="links">
                                        <NavLink className="link" to="/" onClick={toggleMenu}>
                                            {t(translationTag + "home")}
                                            <div className="next-btn">
                                                <FontAwesomeIcon icon={faChevronRight} />
                                            </div>
                                        </NavLink>
                                        <NavLink className="link" to="Services" onClick={toggleMenu}>
                                            {t(translationTag + "services")}
                                            <div className="next-btn">
                                                <FontAwesomeIcon icon={faChevronRight} />
                                            </div>
                                        </NavLink>
                                        <NavLink className="link" to="AboutUs" onClick={toggleMenu}>
                                            {t(translationTag + "about_us")}
                                            <div className="next-btn">
                                                <FontAwesomeIcon icon={faChevronRight} />
                                            </div>
                                        </NavLink>
                                        <NavLink className="link no-border" to="ContactUs" onClick={toggleMenu}>
                                            {t(translationTag + "contactus")}
                                            <div className="next-btn">
                                                <FontAwesomeIcon icon={faChevronRight} />
                                            </div>
                                        </NavLink>
                                    </div>

                                    <div className="lang-menu">
                                        <DropDownMenu
                                            languages={['ar', 'en']}
                                            customOnClick={toggleMenu}
                                        />
                                    </div>
                                </div>
                            </div>
                            {isOpen && <div className="backdrop" onClick={toggleMenu} />}
                        </>
                }
            </header>
            <main>
                <Outlet></Outlet>
                <Footer />
            </main>
        </div>
    )
}