// IMPORTING IMAGES/ICONS
import {
    havcSystemSlide,
    automationSystemSlide,
    plumbingSystemSlide,
    fireSystemSlide,
    audioSystemSlide,
    securitySystemSlide,
    networkSystemSlide,
    havcSystemIcon,
    automationSystemIcon,
    plumbingSystemIcon,
    fireSystemIcon,
    audioSystemIcon,
    securitySystemIcon,
    networkSystemIcon
} from "../../../../Images/ImageComponents"

// TRANSLATION TAG FOR COMPONENT'S TEXTUAL CONTENT
const translationTag = "pages.home.services_section."

export const slides = [
    {
        index: 0,
        image: havcSystemSlide,
        icon: havcSystemIcon,
        title: translationTag + "havc_system.title",
        text: translationTag + "havc_system.text",
    },
    {
        index: 1,
        image: automationSystemSlide,
        icon: automationSystemIcon,
        title: translationTag + "automation_system.title",
        text: translationTag + "automation_system.text",
    },
    {
        index: 2,
        image: plumbingSystemSlide,
        icon: plumbingSystemIcon,
        title: translationTag + "plumbing_system.title",
        text: translationTag + "plumbing_system.text",
    },
    {
        index: 3,
        image: fireSystemSlide,
        icon: fireSystemIcon,
        title: translationTag + "fire_system.title",
        text: translationTag + "fire_system.text",
    },
    {
        index: 4,
        image: audioSystemSlide,
        icon: audioSystemIcon,
        title: translationTag + "audio_system.title",
        text: translationTag + "audio_system.text",
    },
    {
        index: 5,
        image: securitySystemSlide,
        icon: securitySystemIcon,
        title: translationTag + "security_system.title",
        text: translationTag + "security_system.text",
    },
    {
        index: 6,
        image: networkSystemSlide,
        icon: networkSystemIcon,
        title: translationTag + "network_system.title",
        text: translationTag + "network_system.text",
    },
];