// IMPORTING LIBRARIES
import React from 'react'
import { useTranslation } from "react-i18next";

// IMPORTING COMPONENTS
import TitleContainer1 from 'Common/Components/TitleContainers/TitleContainer1/TitleContainer1';
import SwipeableSlider from 'Common/Components/Sliders/SwipeableSlider/SwipeableSlider';

//  IMPORTING SECTION'S DATA
import { slides } from './servicesData';

// IMPORTING STYLE
import './servicesSection.css'

// TRANSLATION TAG FOR COMPONENT'S TEXTUAL CONTENT
const translationTag = "pages.home.services_section."

export default function ServicesSection() {
    const { t } = useTranslation();
    return (
        <div className='home__services-section'>
            <div className='title__container'>
                <TitleContainer1
                    upperTitle={t(translationTag + "upper_title")}
                    mainTitle={t(translationTag + "main_title")}
                    rectangleIcon={true}
                />
            </div>
            <SwipeableSlider
                slides={slides}
                afterSliderColor={"#FFFF"}
            />
        </div>
    )
}