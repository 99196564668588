// IMPORTING IMAGES/ICONS
import {
    excellenceIcon,
    customerFocusIcon,
    innovationIcon,
    integrityIcon,
    collaborationIcon
} from "../../../../Images/ImageComponents";

// TRANSLATION TAG FOR COMPONENT'S TEXTUAL CONTENT
const translationTag = "pages.about_us.values_section."

export const values = [
    {
        index: 0,
        image: excellenceIcon,
        title: translationTag + "excellence_card.title",
        content: translationTag + "excellence_card.content",
    },
    {
        index: 1,
        image: customerFocusIcon,
        title: translationTag + "customer_card.title",
        content: translationTag + "customer_card.content",
    },
    {
        index: 2,
        image: innovationIcon,
        title: translationTag + "innovation_card.title",
        content: translationTag + "innovation_card.content",
    },
    {
        index: 3,
        image: integrityIcon,
        title: translationTag + "integrity_card.title",
        content: translationTag + "integrity_card.content",
    },
    {
        index: 4,
        image: collaborationIcon,
        title: translationTag + "collaboration_card.title",
        content: translationTag + "collaboration_card.content",
    }
];