// IMPORTING LIBRARIES
import React from 'react'

// IMPORTING STYLE
import './rightDashNavigationBtn.css'

export default function RightDashNavigationBtn({ title, href, style }) {
    return (
        <a className="right-dash-button" style={style} href={href}>{title}</a>
    )
}