// IMPORTING LIBRARIES
import React from 'react'

// IMPORTING COMPONENTS
import WriteToUsForm from 'Common/Components/WriteToUsForm/WriteToUsForm'

// IMPORTING STYLE
import './formSection.css'
import './formSectionResponsive.css'

export default function FormSection() {
    return (
        <div className='contactus__form-section'>
            <WriteToUsForm />
        </div>
    )
}