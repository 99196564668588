import {
    industrialIconServices,
    educationIconServices,
    towersIconServices,
    hospitalityIconServices
} from "../../../../Images/ImageComponents"


// TRANSLATION TAG FOR COMPONENT'S TEXTUAL CONTENT
const translationTag = "pages.services.sectors_section.icon_titles."

export const icons = [
    {
        index: 0,
        icon: industrialIconServices,
        title: translationTag + "industrial",
    },
    {
        index: 1,
        icon: educationIconServices,
        title: translationTag + "education",
    },
    {
        index: 2,
        icon: towersIconServices,
        title: translationTag + "towers",
    },
    {
        index: 3,
        icon: hospitalityIconServices,
        title: translationTag + "hospitality",
    }
];