// IMPORTING LIBRARIES
import React, { useEffect } from 'react'
import { useTranslation } from "react-i18next";

// IMPORTING COMPONENTS
import Clients from 'Common/Components/Clients/Clients';
import MissionSection from './Sections/MissionSection/MissionSection';
import ValuesSection from './Sections/ValuesSection/ValuesSection';

// IMPORTING STYLE
import "./aboutUs.css"
import "./aboutUsResponsive.css"

// TRANSLATION TAG FOR COMPONENT'S TEXTUAL CONTENT
const translationTag = "pages.about_us."

export default function AboutUs() {
    useEffect(() => {
        document.title = "About Us | MEPG";
        window.scrollTo(0, 0);
    }, []);
    const { t } = useTranslation();
    return (
        <>
            <div className='aboutus__background'>
                <div className='container'>
                    <h1>{t(translationTag + "background_text")}</h1>
                    <h2>{t(translationTag + "background_sub_text")}</h2>
                </div>
            </div>
            <MissionSection></MissionSection>
            <ValuesSection></ValuesSection>
            <Clients />
        </>
    )
}