// IMPORTING LIBRARIES
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from 'react-router-dom'

// IMPORTING COMPONENTS
import SocialIcons from '../SocialIcons/SocialIcons'
import BackToTopBtn from '../Buttons/BackToTopBtn/BackToTopBtn';

// IMPORTING IMAGES/ICONS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMobileScreenButton } from '@fortawesome/free-solid-svg-icons'
import { faAt } from '@fortawesome/free-solid-svg-icons'
// import { goldLogo } from 'Images/ImageComponents';
import { goldLogoRotated } from 'Images/ImageComponents';

// IMPORTING STYLE
import "./footer.css"
import "./footerResponsive.css"

// TRANSLATION TAG FOR COMPONENT'S TEXTUAL CONTENT
const translationTagNavbar = "nav.";
const translationTagFooter = "footer.";

const BackToTopBtnDesFontSize = 60;
const BackToTopBtnMobFontSize = 40;

export default function Footer() {
    const { t } = useTranslation();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };
    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className='footer__background'>
            {
                windowWidth > 1028 ?
                    <div className='footer'>
                        <img src={goldLogoRotated} alt='mepg_logo' className='rotated-logo' />
                        <div className='content'>
                            <div className='column'>
                                <h3>{t("footer.quick_links")}</h3>
                                <div className='quick-links'>
                                    <NavLink className="navlink" to="/" >{t(translationTagNavbar + "home")}</NavLink>
                                    <NavLink className="navlink" to="Services" >{t(translationTagNavbar + "services")}</NavLink>
                                    <NavLink className="navlink" to="AboutUs" >{t(translationTagNavbar + "about_us")}</NavLink>
                                    <NavLink className="navlink" to="ContactUs" >{t(translationTagNavbar + "contactus")}</NavLink>
                                </div>
                            </div>
                            <div className='column'>
                                <h3>{t(translationTagFooter + "reach_us")}</h3>
                                <div className='reach-us'>
                                    <div className='link__container'>
                                        <NavLink className="navlink" to={t(translationTagFooter + "address_link")} target="_blank">
                                            {t(translationTagFooter + "address")}
                                        </NavLink>
                                    </div>
                                    <div className='link__container'>
                                        <FontAwesomeIcon icon={faAt} className='icon' />
                                        <NavLink className="navlink" to={"mailto:" + t(translationTagFooter + "email")} target="_blank">
                                            {t(translationTagFooter + "email")}
                                        </NavLink>
                                    </div>
                                    <div className='link__container'>
                                        <FontAwesomeIcon icon={faMobileScreenButton} className='icon' />
                                        <NavLink className="navlink phone" to={"tel:" + t(translationTagFooter + "phone")}>
                                            {t(translationTagFooter + "phone")}
                                        </NavLink>
                                    </div>
                                </div>
                                <div className='link__container social-icons'>
                                    <SocialIcons></SocialIcons>
                                </div>
                            </div>
                            <div className='map'>
                                <iframe title="MEPG Address Map" src={t(translationTagFooter + "map_link")} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                            <div className='top-button'>
                                <BackToTopBtn fontSize={BackToTopBtnDesFontSize} />
                            </div>
                        </div>
                    </div>
                    :
                    <div className='upper-footer'>
                        <div className='row'>
                            <FontAwesomeIcon icon={faAt} className='icon' />
                            <a href={"mailto:" + t(translationTagFooter + "email")}>{t(translationTagFooter + "email")}</a>
                        </div>
                        <div className='row'>
                            <FontAwesomeIcon icon={faMobileScreenButton} className='icon' />
                            <a href={"tel:" + t(translationTagFooter + "phone")}>{t(translationTagFooter + "phone")}</a>
                        </div>
                        <div className='row'>
                            <SocialIcons></SocialIcons>
                        </div>
                        <div className='footer'>
                            {/* <img src={goldLogoRotated} alt='mepg_logo' className='rotated-logo' /> */}
                            <div className='content'>
                                <div className='column'>
                                    <h3>{t("footer.quick_links")}</h3>
                                    <div className='quick-links'>
                                        <NavLink className="navlink" to="/" >{t(translationTagNavbar + "home")}</NavLink>
                                        <NavLink className="navlink" to="Services" >{t(translationTagNavbar + "services")}</NavLink>
                                        <NavLink className="navlink" to="AboutUs" >{t(translationTagNavbar + "about_us")}</NavLink>
                                        <NavLink className="navlink" to="ContactUs" >{t(translationTagNavbar + "contactus")}</NavLink>
                                    </div>
                                </div>
                                <div className='column'>
                                    <h3>{t(translationTagFooter + "reach_us")}</h3>
                                    <div className='reach-us'>
                                        <div className='link__container'>
                                            <NavLink className="navlink" to={t(translationTagFooter + "address_link")} target="_blank">
                                                {t(translationTagFooter + "address")}
                                            </NavLink>
                                        </div>
                                    </div>
                                    <div className='top-button-mobile'>
                                        <BackToTopBtn fontSize={BackToTopBtnMobFontSize} />
                                    </div>
                                </div>
                                <div className='map'>
                                    <iframe title="MEPG Address Map" src={t(translationTagFooter + "map_link")} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </div>
    )
}