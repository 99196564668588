// IMPORTING LIBRARIES
import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from "react-i18next";
import Cookies from 'js-cookie';

// IMPORTING COMPONENTS
import Slide from './Slide/Slide';
import Icon from './Icon/Icon';

// IMPORTING STYLE
import './swipeableSlider.css';

export default function SwipeableSlider({ slides, afterSliderColor }) {
    const { t } = useTranslation();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [touchStart, setTouchStart] = useState(null);
    const [touchEnd, setTouchEnd] = useState(null);
    const [currentSlide, setCurrentSlide] = useState(0); // Current slide index
    const [totalSlides, setTotalSlides] = useState(slides.length); // Total number of slides
    const scrollRef = useRef(null);
    const currentLanguageCode = Cookies.get('i18next') || 'en';

    // the required distance between touchStart and touchEnd to be detected as a swipe
    const minSwipeDistance = 1;

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const slider = document.querySelector('.swipeable-slider');
        const handleScroll = () => {
            const scrollLeft = slider.scrollLeft;
            let slideWidth;
            if (currentLanguageCode === "ar") {
                slideWidth = -slider.clientWidth;
            } else {
                slideWidth = slider.clientWidth;
            }
            const newCurrentSlide = Math.round(scrollLeft / slideWidth);
            setCurrentSlide(newCurrentSlide);
        };
        if (slider) {
            slider.addEventListener('scroll', handleScroll);
            return () => {
                slider.removeEventListener('scroll', handleScroll);
            };
        }
    }, [totalSlides, t]); // Listen for changes in totalSlides and language

    useEffect(() => {
        document.addEventListener('touchstart', onTouchStart);
        document.addEventListener('touchend', onTouchEnd);

        return () => {
            document.removeEventListener('touchstart', onTouchStart);
            document.removeEventListener('touchend', onTouchEnd);
        };

    }, [currentSlide]);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    const onTouchStart = (e) => {
        e.preventDefault(); // Prevent default touch behavior
        setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
        setTouchStart(e.targetTouches[0].clientX);
    };

    const onTouchEnd = () => {
        if (!touchStart || !touchEnd) return;
        const distance = touchStart - touchEnd;
        const isLeftSwipe = distance > minSwipeDistance;
        const isRightSwipe = distance < -minSwipeDistance;
        if (isLeftSwipe || isRightSwipe) {
            const nextSlide = isLeftSwipe ? currentSlide + 1 : currentSlide - 1;
            setCurrentSlide(Math.max(0, Math.min(nextSlide, totalSlides - 1)));
            // Scroll the slider based on the swipe direction
            const slider = document.querySelector('.swipeable-slider');
            if (slider) {
                slider.scrollLeft += isLeftSwipe ? windowWidth : -windowWidth; // Scroll whole width of the screen
            }
        }
    };

    const onMouseDown = (e) => {
        e.preventDefault(); // Prevent default mouse behavior
        setTouchEnd(null); // Reset touchEnd
        setTouchStart(e.clientX);
        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);
    };

    const onMouseMove = (e) => setTouchEnd(e.clientX);

    const onMouseUp = (e) => {
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);

        if (!touchStart || !touchEnd) return;
        const distance = touchStart - touchEnd;
        const isLeftSwipe = distance > minSwipeDistance;
        const isRightSwipe = distance < -minSwipeDistance;
        if (isLeftSwipe || isRightSwipe) {
            const nextSlide = isLeftSwipe ? currentSlide + 1 : currentSlide - 1;
            setCurrentSlide(Math.max(0, Math.min(nextSlide, totalSlides - 1)));
            // Scroll the slider based on the swipe direction
            const slider = document.querySelector('.swipeable-slider');
            if (slider) {
                slider.scrollLeft += isLeftSwipe ? windowWidth : -windowWidth; // Scroll whole width of the screen
            }
        }

        // Reset touchStart and touchEnd
        setTouchStart(null);
        setTouchEnd(null);
    };

    const goToSlide = (index) => {
        const slider = document.querySelector('.swipeable-slider');
        let slideWidth;
        if (currentLanguageCode === "ar") {
            slideWidth = -windowWidth;
        } else {
            slideWidth = windowWidth;
        }
        if (slider && index >= 0 && index < totalSlides) {
            slider.scrollLeft = slideWidth * index; // Scroll to the specified slide
            setCurrentSlide(index);
        }
    };

    function scrollDown() {
        scrollRef.current?.scrollIntoView({ block: "start", behavior: 'smooth' });
    }

    return (
        windowWidth > 1028 ?
            <div className='swipeable-slider_main_container'>
                <div className='background'>
                    <div className="icons">
                        {slides.map((slide) => (
                            <Icon
                                key={slide.index}
                                index={slide.index}
                                image={slide.icon}
                                tag={t(slide.title)}
                                customHoverEvent={() => goToSlide(slide.index)}
                            />
                        ))}
                    </div>
                    <div
                        className="swipeable-slider"
                        onTouchStart={onTouchStart}
                        onTouchEnd={onTouchEnd}
                        onMouseDown={onMouseDown}
                        onMouseMove={onMouseMove}
                        onMouseUp={onMouseUp}
                    >
                        <div className="slides">
                            {slides.map((slide) => (
                                <Slide
                                    key={slide.index}
                                    index={slide.index}
                                    image={t(slide.image)}
                                    title={t(slide.title)}
                                    text={t(slide.text)}
                                />
                            ))}
                        </div>
                    </div>
                </div>
                <div className="circles" style={{ background: afterSliderColor }}>
                    {slides.map((slide) => (
                        <div
                            key={slide.index}
                            className={`circle ${currentSlide === slide.index ? 'active' : ''}`}
                            onClick={() => goToSlide(slide.index)}
                        />
                    ))}
                </div>
            </div>
            :
            <div className='swipeable-slider_main_container'>
                <div className='background'>
                    <div className="icons">
                        {slides.map((slide) => (
                            <Icon
                                key={slide.index}
                                index={slide.index}
                                image={slide.icon}
                                tag={t(slide.title)}
                                customClickEvent={() => {
                                    scrollDown();
                                    goToSlide(slide.index);
                                }}
                            />
                        ))}
                    </div>
                    <div className="swipeable-slider">
                        <div className="slides" ref={scrollRef}>
                            {slides.map((slide) => (
                                <Slide
                                    key={slide.index}
                                    index={slide.index}
                                    image={t(slide.image)}
                                    title={t(slide.title)}
                                    text={t(slide.text)}
                                />
                            ))}
                        </div>
                    </div>
                </div>

                <div className="circles" style={{ background: afterSliderColor }}>
                    {slides.map((slide) => (
                        <div
                            key={slide.index}
                            className={`circle ${currentSlide === slide.index ? 'active' : ''}`}
                            onClick={() => goToSlide(slide.index)}
                        />
                    ))}
                </div>
            </div>
    )
}