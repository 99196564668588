// IMPORTING LIBRARIES
import React from 'react'
import { useTranslation } from "react-i18next";

// IMPORTING COMPONENTS
import TitleContainer1 from 'Common/Components/TitleContainers/TitleContainer1/TitleContainer1';
import ArrowSlider from 'Common/Components/Sliders/ArrowSlider/ArrowSlider';

//  IMPORTING SECTION'S DATA
import { sectors } from './sectorsData';

// IMPORTING STYLE
import './sectorsSectionMobile.css'

// TRANSLATION TAG FOR COMPONENT'S TEXTUAL CONTENT
const translationTag = "pages.home.sectors_section."

export default function SectorsSectionMobile() {
    const { t } = useTranslation();
    return (
        <div className='home__sectors-section-mobile'>
            <TitleContainer1
                upperTitle={t(translationTag + "upper_title")}
                mainTitle={t(translationTag + "main_title")}
                rectangleIcon={true}
            />
            <div className='arrow-slider__container'>
                <ArrowSlider
                    slides={sectors}
                />
            </div>
        </div>
    )
}