import React from 'react'
import { useTranslation } from "react-i18next";

// IMPORTING COMPONENTS
import TitleContainer3 from 'Common/Components/TitleContainers/TitleContainer3/TitleContainer3'
import ValuesSectionCards from 'Pages/AboutUs/Components/ValuesSectionCards/ValuesSectionCards'

//  IMPORTING SECTION'S DATA
import { values } from './valuesData';

// IMPORTING STYLE
import './valuesSection.css'

// TRANSLATION TAG FOR COMPONENT'S TEXTUAL CONTENT
const translationTag = "pages.about_us.values_section."

export default function ValuesSection() {
    const { t } = useTranslation();
    return (
        <div className='aboutus__values-section'>
            <div className='container'>
                <TitleContainer3
                    upperTitle={t(translationTag + "title")}
                    content={t(translationTag + "title_content")}
                    rectangleIcon={true}
                />
                <div className="list">
                    {values.filter((item, index) => index < 3).map((value) => (
                        <ValuesSectionCards
                            key={value.index}
                            index={value.index}
                            image={value.image}
                            title={t(value.title)}
                            content={t(value.content)}
                        />
                    ))}
                </div>
                <div className="list">
                    {values.filter((item, index) => index >= 3).map((value) => (
                        <ValuesSectionCards
                            key={value.index}
                            index={value.index}
                            image={value.image}
                            title={t(value.title)}
                            content={t(value.content)}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}