// IMPORTING LIBRARIES
import React from 'react'

// IMPORTING STYLE
import "./contactCardsSectionCard.css"

export default function ContactCardsSectionCard({ index, image, title, content }) {
    return (
        <div id={index} className='contactus__cards-section__card'>
            <div className='icon__container'>
                <img src={image} alt={index} />
            </div>
            <h3>{title}</h3>
            <h4>{content}</h4>
        </div>
    )
}