// IMPORTING LIBRARIES
import React from 'react'

// IMPORTING STYLE
import './titleContainer1.css'

export default function TitleContainer1({ upperTitle, mainTitle, rectangleIcon }) {
    /* 
    If you want the title to have the surronding rectangle 
    use "true" for rectangleIcon when importing the component.
    */
    if (rectangleIcon) {
        return (
            <div className="title-container-1">
                <div className='title_rectangle-up' ></div>
                <p className="upper_title">{upperTitle}</p>
                <p className='main_title'>{mainTitle}</p>
                <div className='title_rectangle-down' ></div>
            </div>
        )
    }
    return (
        <div className="title-container-1">
            <p className="upper_title">{upperTitle}</p>
            <p className='main_title'>{mainTitle}</p>
        </div>
    )
}