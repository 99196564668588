/*
    CONVERTING NUMBERS TO THE CURRENT LANGUAGE
*/
export default function ConvertNum(num, currentLanguageCode) {
    switch (currentLanguageCode) {
        case "ar":
            return (num).toLocaleString("ar-u-nu-arab", { minimumIntegerDigits: 2, useGrouping: false });
        case "en":
            return (num).toLocaleString("en", { minimumIntegerDigits: 2, useGrouping: false });
        default:
            return (num).toLocaleString("en", { minimumIntegerDigits: 2, useGrouping: false });
    }
}