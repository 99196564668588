import React from 'react'

// IMPORTING STYLE
import "./icon.css"

const imageStyle = {
    width: '90%',
    height: 'auto',
}

export default function Icon({ index, image, tag, customHoverEvent, customClickEvent }) {
    return (
        <div id={index} className='swipeable-icon' onMouseOver={customHoverEvent} onClick={customClickEvent}>
            <img src={image} alt={index} style={imageStyle} />
            <h3>{tag}</h3>
        </div>
    )
}