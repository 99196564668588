// IMPORTING LIBRARIES
import React from 'react';
import Cookies from 'js-cookie';
import { useTranslation } from "react-i18next";

// IMPORTING FUNCTIONS
import ConvertNum from "Common/Utils/ConvertNum";

// IMPORTING IMAGES/ICONS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';

// IMPORTING STYLE
import "./slide.css"

export default function Slide({ index, image, tag, prevSlide, nextSlide }) {
    const { t } = useTranslation();
    const currentLanguageCode = Cookies.get('i18next') || 'en';
    return (
        <div className="arrow-slide">
            <div className='upper-bar'>
                <div className="slide-counter">.{ConvertNum(index + 1, currentLanguageCode)}</div>
                <div className="controls">
                    <FontAwesomeIcon icon={faArrowLeftLong} className='arrows' onClick={prevSlide} />
                    <FontAwesomeIcon icon={faArrowRightLong} className='arrows' onClick={nextSlide} />
                </div>
            </div>
            <div className="slide-content">
                <img src={image} alt="slide" />
                <h2>{t(tag)}</h2>
            </div>
        </div>
    )
}