// IMPORTING LIBRARIES
import React from 'react'
import { useTranslation } from "react-i18next";

// IMPORTING COMPONENTS
import TitleContainer2 from 'Common/Components/TitleContainers/TitleContainer2/TitleContainer2'

// IMPORTING STYLE
import "./missionSection.css"
import "./missionSectionResponsive.css"

// TRANSLATION TAG FOR COMPONENT'S TEXTUAL CONTENT
const translationTag = "pages.about_us.mission_section."

export default function MissionSection() {
    const { t } = useTranslation();
    return (
        <div className='aboutus__mission-section'>
            <div className='container'>
                <div className='left'>
                    <TitleContainer2>{t(translationTag + "title")}</TitleContainer2>
                </div>
                <div className='right'>
                    <div className='text'>
                        {t(translationTag + "content")}
                    </div>
                </div>
            </div>
        </div>
    )
}