// IMPORTING LIBRARIES
import React from 'react'
import { useTranslation } from "react-i18next";

// IMPORTING IMAGES/ICONS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF } from '@fortawesome/free-brands-svg-icons'
import { faXTwitter } from '@fortawesome/free-brands-svg-icons'
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons'

// IMPORTING STYLE
import './socialIcons.css'

// TRANSLATION TAG FOR COMPONENT'S TEXTUAL CONTENT
const FACEBOOK_URL = "components.social_icons.facebook_url";
const TWITTER_URL = "components.social_icons.twitter_url";
const LINKEDIN_URL = "components.social_icons.linkedin_url";


export default function SocialIcons() {
    const { t } = useTranslation();
    return (
        <div className='social-bar'>
            <a href={t(TWITTER_URL)} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faXTwitter} className='icon' /></a>
            <a href={t(FACEBOOK_URL)} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faFacebookF} className='icon' /></a>
            <a href={t(LINKEDIN_URL)} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faLinkedinIn} className='icon' /></a>
        </div>
    )
}