// IMPORTING IMAGES/ICONS
import {
    havcSystemIcon,
    automationSystemIcon,
    plumbingSystemIcon,
    fireSystemIcon,
    audioSystemIcon,
    securitySystemIcon,
    networkSystemIcon,
    electricalSystem
} from "../../../../Images/ImageComponents"

// TRANSLATION TAG FOR COMPONENT'S TEXTUAL CONTENT
const translationTag = "pages.services.services_section.cards."

export const cards = [
    {
        index: 0,
        icon: havcSystemIcon,
        title: translationTag + "havc_system.title",
        content: translationTag + "havc_system.content",
    },
    {
        index: 1,
        icon: automationSystemIcon,
        title: translationTag + "automation_system.title",
        content: translationTag + "automation_system.content",
    },
    {
        index: 2,
        icon: plumbingSystemIcon,
        title: translationTag + "plumbing_system.title",
        content: translationTag + "plumbing_system.content",
    },
    {
        index: 3,
        icon: fireSystemIcon,
        title: translationTag + "fire_system.title",
        content: translationTag + "fire_system.content",
    },
    {
        index: 4,
        icon: audioSystemIcon,
        title: translationTag + "audio_system.title",
        content: translationTag + "audio_system.content",
    },
    {
        index: 5,
        icon: securitySystemIcon,
        title: translationTag + "security_system.title",
        content: translationTag + "security_system.content",
    },
    {
        index: 6,
        icon: networkSystemIcon,
        title: translationTag + "network_system.title",
        content: translationTag + "network_system.content",
    },
    {
        index: 7,
        icon: electricalSystem,
        title: translationTag + "electrical_system.title",
        content: translationTag + "electrical_system.content",
    },
];