import {
    industrialIcon,
    educationIcon,
    towersIcon,
    hospitalityIcon
} from "../../../../Images/ImageComponents";

// TRANSLATION TAG FOR COMPONENT'S TEXTUAL CONTENT
const translationTag = "pages.home.sectors_section."

export const sectors = [
    {
        index: 0,
        image: industrialIcon,
        tag: translationTag + "industrial",
    },
    {
        index: 1,
        image: educationIcon,
        tag: translationTag + "education",
    },
    {
        index: 2,
        image: towersIcon,
        tag: translationTag + "towers",
    },
    {
        index: 3,
        image: hospitalityIcon,
        tag: translationTag + "hospitality",
    }
];
