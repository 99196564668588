// IMPORTING LIBRARIES
import React from 'react'

// IMPORTING STYLE
import './titleContainer4.css'

/*
    Separating the first word of every title to color the rest of the string to yellow.
*/
function separateFirstWord(inputString) {
    // Check if the inputString is not empty or just spaces
    if (!inputString.trim()) {
        return { firstWord: '', restOfString: '' };
    }
    // Find the index of the first space
    const firstSpaceIndex = inputString.indexOf(' ');

    // If there's no space, the entire string is considered as the first word
    if (firstSpaceIndex === -1) {
        return { firstWord: inputString, restOfString: '' };
    }
    // Extract the first word and the rest of the string
    const firstWord = inputString.substring(0, firstSpaceIndex) + ' ';
    const restOfString = inputString.substring(firstSpaceIndex + 1);

    return [firstWord, restOfString];
}

export default function TitleContainer4({ upperTitle, mainTitle, rectangleIcon }) {
    /* 
    If you want the title to have the surrounding rectangle 
    use "true" for rectangleIcon when importing the component.
    */
    const [upperTitleFirstWord, upperTitleRestOfString] = separateFirstWord(upperTitle);
    const [mainTitleFirstWord, mainTitleRestOfString] = separateFirstWord(mainTitle);
    if (rectangleIcon) {
        return (
            <div className="title-container-4">
                <div className='title_rectangle-up' ></div>
                <div className='middle_container'>
                    <div className='middle_line' ></div>
                    <div className='text_container'>
                        <p className="upper_title">{upperTitleFirstWord}
                            <span>{upperTitleRestOfString}</span>
                        </p>
                        <p className='main_title'>{mainTitleFirstWord}
                            <span>{mainTitleRestOfString}</span>
                        </p>
                    </div>
                </div>
                <div className='title_rectangle-down' ></div>
            </div>
        )
    }
    return (
        <div className="title-container-4">
            <div className='middle_container'>
                <div className='text_container'>
                    <p className="upper_title">{upperTitleFirstWord}
                        <span>{upperTitleRestOfString}</span>
                    </p>
                    <p className='main_title'>{mainTitleFirstWord}
                        <span>{mainTitleRestOfString}</span>
                    </p>
                </div>
            </div>
        </div>
    )
}