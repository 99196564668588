// IMPORTING LIBRARIES
import React from 'react'

// IMPORTING IMAGES/ICONS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleArrowUp } from '@fortawesome/free-solid-svg-icons'

// IMPORTING STYLE
import './backToTopBtn.css'

// Function to scroll to the top of the page
const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
};

export default function BackToTopButton({ fontSize }) {
    const buttonSize = `${fontSize * (2 / 3)}px`;
    const buttonStyle = {
        width: buttonSize,
        height: buttonSize,
        fontSize: fontSize,
    };

    return (
        <div className='BackToTop-button' onClick={scrollToTop} style={buttonStyle}>
            <FontAwesomeIcon icon={faCircleArrowUp} />
        </div>
    )
}