// IMPORTING LIBRARIES
import React, { useEffect, useState, useRef } from 'react'
import { useTranslation } from "react-i18next";

// IMPORTING COMPONENTS
import BlackBtn from '../Buttons/BlackBtn/BlackBtn';

// IMPORTING STYLE
import './enquiryFormMobile.css'

// TRANSLATION TAG FOR COMPONENT'S TEXTUAL CONTENT
const translationTag = "components.enquiry_form."

export default function EnquiryFormMobile() {
    const { t } = useTranslation();
    const [formStatus, setFormStatus] = React.useState("");
    const [isOpen, setIsOpen] = useState(false);
    const scrollRef = useRef(null);
    const onSubmit = (e) => {
        e.preventDefault()
        setFormStatus('Submit')
        const { name, email, phoneNumber } = e.target.elements
        let conFom = {
            name: name.value,
            email: email.value,
            phoneNumber: phoneNumber.value,
        }
        /* Log inputs to console*/
        console.log(conFom)
    }

    /*  Function to scroll down when "REQUEST A QOUTA" button is pressed.   */
    function scrollDown() {
        scrollRef.current?.scrollIntoView({ block: "start", behavior: 'smooth' });
    }

    const toggleForm = () => {
        setIsOpen(!isOpen);
        scrollDown();
    };

    useEffect(() => {
        setFormStatus(t(translationTag + "button"))
    }, [t])

    return (
        <div className='dropdown-enquiry-form__container' ref={scrollRef}>
            <div onClick={toggleForm} className='header__container'>
                <h2 className='header' >{t(translationTag + "header")}</h2>
            </div>
            <form onSubmit={onSubmit} className={`dropdown-enquiry-form ${isOpen ? 'open' : ''}`}>
                <div className='wrap' >
                    <label htmlFor="name" className="name__label" >{t(translationTag + "name")}</label>
                    <input type="text" className="name__input" id="name" autoComplete="on" required />
                </div>
                <div className='wrap'>
                    <label htmlFor="email" className='email__label'>{t(translationTag + "email")}</label>
                    <input type="text" className="email__input" id="email" autoComplete="on" required />
                </div>

                <div className='wrap'>
                    <label htmlFor="phoneNumber" className='phone__label' >{t(translationTag + "phone")}</label>
                    <input type="text" className="phone__input" id="phoneNumber" autoComplete="on" required />
                </div>

                <div className='button__containter'>
                    <BlackBtn>{formStatus}</BlackBtn>
                </div>
            </form>
        </div>
    )
}