// IMPORTING LIBRARIES
import React from 'react'

// IMPORTING COMPONENTS
import Card from './Card/Card';

//  IMPORTING DATA
import { clients } from './ClientsData'

export default function Clients() {
    return (
        <div className='clients__background'>
            <div className="clients">
                <div className="clients__icons">
                    {clients.map((client) => (
                        <Card
                            key={client.index}
                            index={client.index}
                            image={client.image}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}